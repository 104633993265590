import { BlitzPage } from "@blitzjs/next"
import Layout from "app/core/layouts/Layout"
import { useContext } from "react"
import { MainHome } from "../app/core/components/MainHome"
import { LoggedOut } from "../app/core/components/LoggedOut"
import { UserContext } from "pages/_app"
import { gSP } from "app/blitz-server"

const Home: BlitzPage = (a) => {
  const { user } = useContext(UserContext)

  return (
    <Layout title="Home" wide={!user} showBottomBanner={!!user}>
      {user ? <MainHome /> : <LoggedOut />}
    </Layout>
  )
}

export default Home

export const getStaticProps = gSP(async ({ params, ctx }) => {
  return { props: {}, revalidate: 120 }
  // }

  // return {
  //   notFound: true,
  //   revalidate: 15,
  // }
})
