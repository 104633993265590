import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react"
import { DividerWithText, GetStartedButton, Sports, WorksWith } from "app/core/components/HomeItems"
import { validateZodSchema } from "blitz"
import Link from "next/link"
import { useState } from "react"
import { Form, useField } from "react-final-form"
import { IoArrowForwardCircleSharp } from "react-icons/io5"
import { z } from "zod"

export const LoggedOut = () => {
  return (
    <Flex alignItems="center" direction="column">
      <Text
        as="h3"
        fontSize={[26, 32, 36]}
        lineHeight="1.2em"
        fontWeight="light"
        mt={16}
        mb={16}
        letterSpacing={-0.5}
        textAlign="center"
      >
        <Text as="span" textShadow="#aaa 1px 0 10px" fontWeight="bold">
          Discover
        </Text>{" "}
        the soundtrack to your workouts.
      </Text>

      <Flex flexDir="column" alignItems="stretch" w="full" maxW="xl">
        <GetStartedButton />
        {/* <DividerWithText mb={3} mt={3}>
          or
        </DividerWithText>

        <JoinViaEmail /> */}
        <WorksWith mt={16} mb={6} />
      </Flex>

      {/* <Link href="/a/8223671122" passHref>
        <Button as="a" w="full" mb={8}>
          See an Example from Ed 🏃‍♂️
        </Button>
      </Link> */}

      <Box mx={-3} mt={10}>
        <Image alt="Mountain running" src="/mountain.jpeg" mt={4} />
      </Box>

      <Box
        fontSize={[24, 36, 42]}
        lineHeight={1.3}
        fontWeight="light"
        letterSpacing={-0.3}
        mt={20}
        mb={24}
      >
        <Text as="p" ml={0} mb={8}>
          With Spava, you can keep track of the music you listen to during your workouts and share
          it with friends.
        </Text>
        <Text as="p" ml={0}>
          It&apos;s a fun way to discover new music and connect with more athletes. Join now and be
          part of the community!
        </Text>
      </Box>

      <Sports />

      <Box mx={-3} mt={24}>
        <Image alt="Skiing" src="/ski.jpeg" mb={2} />
      </Box>

      <Heading as="h3" mt={20} mb={10} fontWeight="semibold" letterSpacing="tight">
        From the Spava Blog
      </Heading>

      <a
        target="_blank"
        href="https://blog.spava.club/en/articles/6933850-the-spotify-x-strava-integration-we-ve-all-been-waiting-for"
        rel="noreferrer"
      >
        <Card w="full" boxShadow="dark-lg">
          <CardHeader>
            <Heading size="md">The Spotify x Strava Integration we’ve all been waiting for</Heading>
          </CardHeader>
          <CardBody py={0} textAlign="justify">
            <Text>
              Ever wondered what playlist powered someone to a sub 20 minute 5k or a half marathon
              PB? Music has been proven to enhance athletic performance through increased motivation
              and happiness. In our survey of 57 social runners, 86% said they listened to music
              whilst...
            </Text>
          </CardBody>
          <CardFooter>
            <Button
              w="full"
              rightIcon={<IoArrowForwardCircleSharp />}
              as="a"
              href="https://blog.spava.club/en/articles/6933850-the-spotify-x-strava-integration-we-ve-all-been-waiting-for"
              target="_blank"
            >
              Read
            </Button>
          </CardFooter>
        </Card>
      </a>

      <Heading as="h3" mt={24} mb={8} fontWeight="semibold" letterSpacing="tight">
        Our Weekly Curated Playlist
      </Heading>

      <Box
        as="iframe"
        // style="border-radius:12px"
        borderRadius="16px"
        src="https://open.spotify.com/embed/playlist/4lc6Tr6DI8370uVNHCHiIi?utm_source=generator&theme=0"
        width="100%"
        height="386"
        frameBorder="0"
        allowFullScreen={false}
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        mb={4}
      ></Box>

      <Flex flexDir="column" alignItems="stretch" w="full" maxW="xl">
        <GetStartedButton mt={20} />

        <DividerWithText mb={2} mt={2}>
          or
        </DividerWithText>

        <Link href="/stats" passHref>
          <Button as="a" w="full">
            See some stats!
          </Button>
        </Link>
      </Flex>
    </Flex>
  )
}

const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const ContactSchema = z.object({
  contact: z.union([
    z.string().regex(phoneRegex, "Invalid Phone or Email"),
    z.string().regex(emailRegex, "Invalid Phone or Email"),
  ]),
})

function JoinViaEmail() {
  // const intercom = useIntercom()
  const toast = useToast()
  const [submitted, setSubmitted] = useState(false)

  const submit = (e) => {
    const contact = e?.contact?.trim() as string

    if (typeof contact === "string" && contact.length > 2) {
      if (contact.match(phoneRegex)) {
        // intercom.update({
        //   customAttributes: {
        //     leadCreatedReason: "homepageInputJs",
        //   },
        //   phone: contact,
        // })

        toast({ position: "top", title: "We'll message you soon!" })
        setSubmitted(true)
      } else if (contact.match(emailRegex)) {
        // intercom.update({
        //   customAttributes: {
        //     leadCreatedReason: "homepageInputJs",
        //   },
        //   email: contact,
        // })
        setSubmitted(true)
      } else {
        console.error("unable to submit lead", contact)
        return { error: "could not submit" }
      }
    }
  }
  return (
    <Form
      validate={validateZodSchema(ContactSchema)}
      onSubmit={submit}
      // onSubmit={onSubmit}
      render={({ handleSubmit, submitError, values, valid, form, errors, submitErrors }) => {
        return (
          <form
            onSubmit={async (e) => {
              e.preventDefault()
              if (valid) {
                await handleSubmit(e)
              }
              // form.reset()
              // form.resetFieldState("contact")
            }}
            className="form"
          >
            {submitted ? (
              <InputGroup size="md">
                <Input
                  disabled={true}
                  pr="6rem"
                  type="text"
                  placeholder="Thanks! We'll message you soon."
                />
              </InputGroup>
            ) : (
              <EmailInput />
            )}

            {submitError && (
              <div role="alert" style={{ color: "red" }}>
                {submitError}
              </div>
            )}
          </form>
        )
      }}
    />
  )
}

const EmailInput = () => {
  const {
    input,
    meta: { touched, error, submitError, submitting },
  } = useField("contact", {
    // parse: (v) => (v === "" ? null : v?.trim()),
    format: (v) => (typeof v === "string" ? v.trim() : ""),
    // ...fieldProps,
    // { }
  })

  const normalizedError = Array.isArray(error) ? error.join(", ") : error || submitError

  return (
    <FormControl>
      <FormLabel marginInlineEnd={0}>
        {/* <Input {...input} disabled={submitting} {...props} ref={ref} /> */}

        <InputGroup size="md">
          <Input
            {...input}
            disabled={submitting}
            pr="6rem"
            type="text"
            placeholder="Get a link via phone or email"
          />

          <InputRightElement width="5rem">
            <Button h="1.75rem" size="sm" mr={2} type="submit">
              Submit
            </Button>
          </InputRightElement>
        </InputGroup>

        {touched && normalizedError && input.value !== "" && (
          <Text fontSize={12} color="red.500" pt={1} textStyle="italic">
            {normalizedError}
          </Text>
        )}
      </FormLabel>
    </FormControl>
  )
}
